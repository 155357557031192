<template>
<div class="flex items-center cursor-pointer" @click="show = !show">
    <div class="text-md text-gray-600 dark:text-white/75 font-semibold">{{ bots.localization['bots/tradingview/menu/title'] }}</div>
    <n-icon class="ml-2 transition transform text-base" :class="show ? 'rotate-90' : ''">
        <IosArrowRtl24Filled />
    </n-icon>
</div>
<n-collapse-transition :show="show">
    <div class="mt-4 text-left">
        <rb-checkbox
            :label="'- ' + bots.localization['bots/tradingview/tradingview_enabled']"
            v-model:checked="dataForm.settings.tradingview_enabled" />
        <div v-if="dataForm.settings.tradingview_enabled" class="mt-4">
            <n-alert class="rounded-md mt-4" type="warning" size="small">
                <div class="notes text-xs" v-html="bots.localization['bots/tradingview/info']"></div>
            </n-alert>

            <div class="text-mt text-gray-600 dark:text-white/75 whitespace-nowrap mt-4">
                {{ bots.localization['bots/tradingview/tradingview_hook_url'] }}
            </div>
            <div
                class="mt-2 w-full flex flex-row items-center justify-between bg-gray-100 dark:bg-darkbg p-2 rounded-md text-base cursor-pointer"
                @click="copy(dataForm.settings.tradingview_hook_url)">
                {{ dataForm.settings.tradingview_hook_url }}
                <n-icon class="ml-4 text-main" size="25">
                    <Copy16Regular />
                </n-icon>
            </div>
            <n-alert class="rounded-md mt-4" type="warning" size="small">
                <div class="notes text-xs" v-html="bots.localization['bots/tradingview/tradingview_hook_url/info']"></div>
            </n-alert>

            <div class="text-mt text-gray-600 dark:text-white/75 whitespace-nowrap mt-4">
                {{ bots.localization['bots/tradingview/tradingview_msg'] }}
            </div>
            <n-card
                class="relative rounded-md bg-gray-100 dark:bg-darkbg"
                content-style="padding: 0;">
                <n-icon class="absolute top-2 right-2 cursor-pointer text-main" size="25" @click="copy(dataForm.settings.tradingview_msg)">
                    <Copy16Regular />
                </n-icon>
                <div class="text-xs pl-2 pr-8 py-2">
                    <n-code
                        word-wrap
                        :code="dataForm.settings.tradingview_msg"
                        language="json" />
                </div>
            </n-card>
            <n-alert class="rounded-md mt-4" type="warning" size="small">
                <div class="notes text-xs" v-html="bots.localization['bots/tradingview/tradingview_msg/info']"></div>
            </n-alert>
        </div>
    </div>
</n-collapse-transition>
</template>

<script>
// vue
import { ref, computed } from 'vue';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useBots } from '@/store/bots';

// naive-ui
import {
    NCode,
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NCollapseTransition,
    useNotification } from 'naive-ui';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbCheckbox from '@components/rb-checkbox';
import RbPairSelect from '@components/rb-pair-select';

// icons
import { Copy16Regular, Dismiss16Filled, IosArrowRtl24Filled, DeleteDismiss24Regular } from '@vicons/fluent';

export default {
    name: 'bot-trading-view-signals',
    components: {
        NCode,
        NIcon,
        NCard,
        NAlert,
        RbInput,
        NButton,
        NDivider,
        RbSelect,
        RbCheckbox,
        RbPairSelect,
        Copy16Regular,
        Dismiss16Filled,
        IosArrowRtl24Filled,
        NCollapseTransition,
        DeleteDismiss24Regular,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const bots = useBots();
        const refs = useRefs();

        // i18n
        const { t } = useI18n();

        // naive-ui
        const notification = useNotification();

        // vars
        const { _ } = window;
        const dataForm = bots.dataForm;
        const show = ref(dataForm.settings.tradingview_enabled);

        const copy = s => {
            navigator.clipboard.writeText(s);
            notification.success({
                content: t('copied'),
                duration: 2500,
                keepAliveOnHover: true,
            });
        };

        return {
            gl,
            show,
            bots,
            dataForm,
            copy,
        };
    },
};
</script>